<template>
<div class="My">
	
	<div class="Nav" :style="'width:' + ($store.getters.getIsLeftMenuCollapse ? '60px':'220px')">
		
		<div class="Title" @click="SwitchMenuCollapse(false)">
			<ul v-if="$store.getters.getIsLeftMenuCollapse == false">
				<h1>{{$store.getters.getAppName}} - 管理后台</h1>
				<li>欢迎回来</li>
			</ul>
		</div>
		
		<div class="Menus">
			<div style="width: 100%;height: 100%;">
				<vuescroll :ops="vuescroll_ops" ref="vs">
					<el-menu
					      default-active="1-1"
						  :collapse="$store.getters.getIsLeftMenuCollapse"
					      background-color="rgba(38,59,97,1)"
					      text-color="rgba(181,195,212,1)"
						  :collapse-transition="false"
					      active-text-color="rgba(40,149,251,1)" style="border-right-width: 0px;">
						  
						  
						  <div class="Tips" v-if="$store.getters.getIsLeftMenuCollapse == false">
						  	综合
						  </div>
						  
						   <el-menu-item index="101" @click="$Jump('/do/user/user_list')">
						     <i class="el-icon-user-solid"></i>
						     <span slot="title">用户列表</span>
						   </el-menu-item>
						   
						
						   
						   <el-menu-item index="107" @click="$Jump('/do/news/list')">
						     <i class="el-icon-tickets"></i>
						     <span slot="title">新闻管理</span>
						   </el-menu-item>
						   
						   <el-menu-item index="141" @click="$Jump('/do/ad/list')">
						     <i class="el-icon-date"></i>
						     <span slot="title">广告管理</span>
						   </el-menu-item>
						   
						   <el-menu-item index="145" @click="$Jump('/do/finance/count')">
						     <i class="el-icon-bank-card"></i>
						     <span slot="title">平台财务管理</span>
						   </el-menu-item>
						   
						   <el-menu-item index="241" @click="$Jump('/do/help/list')">
						     <i class="el-icon-help"></i>
						     <span slot="title">帮助管理</span>
						   </el-menu-item>
						   
						   <el-menu-item index="242" @click="$Jump('/do/workorder/list')">
						     <i class="el-icon-c-scale-to-original
"></i>
						     <span slot="title">工单管理</span>
						   </el-menu-item>
					
						   <el-menu-item index="2432" @click="$Jump('/do/menu/list')">
							 <i class="el-icon-set-up"></i>
							 <span slot="title">商城导航</span>
						   </el-menu-item>
						  
						 <div class="Tips" v-if="$store.getters.getIsLeftMenuCollapse == false">
						 	电商管理
						 </div>
						 
						  
						  <el-menu-item index="18" @click="$Jump('/do/goods/goods_category_list')">
						    <i class="el-icon-guide"></i>
						    <span slot="title">平台商品分类</span>
						  </el-menu-item>
						  
						  <el-menu-item index="1512" @click="$Jump('/do/goods/list')">
						    <i class="el-icon-shopping-bag-2"></i>
						    <span slot="title">商品管理</span>
						  </el-menu-item>

						<el-menu-item index="31511" @click="$Jump('/do/coupon/coupon_list')">
						    <i class="el-icon-money"></i>
						    <span slot="title">优惠券管理</span>
						  </el-menu-item>
						  
					      <el-menu-item index="2" @click="$Jump('/do/order/order_list')">
					        <i class="el-icon-s-order"></i>
					        <span slot="title">订单管理</span>
					      </el-menu-item>
						  
						  <el-menu-item index="24" @click="$Jump('/do/brand/brand_list')">
						    <i class="el-icon-collection-tag"></i>
						    <span slot="title">品牌管理</span>
						  </el-menu-item>
						  
						  <el-menu-item index="25" @click="$Jump('/do/shop/shop_list')">
						    <i class="el-icon-s-shop"></i>
						    <span slot="title">店铺管理</span>
						  </el-menu-item>
						  
						  <el-menu-item index="26" @click="$Jump('/do/shop/shop_apply_list')">
						    <i class="el-icon-s-shop"></i>
						    <span slot="title">店铺申请审核</span>
						  </el-menu-item>
	
						  <el-menu-item index="1516" @click="$Jump('/do/keywords/list')">
						    <i class="el-icon-search"></i>
						    <span slot="title">用户搜索词</span>
						  </el-menu-item>
						  
						  <el-menu-item index="15316" @click="$Jump('/do/goods_comment/list')">
						    <i class="el-icon-chat-dot-round"></i>
						    <span slot="title">商品评价</span>
						  </el-menu-item>
						  
						  <div class="Tips" v-if="$store.getters.getIsLeftMenuCollapse == false">
						  	系统管理
						  </div>
						  
						   <el-menu-item index="40" @click="$Jump('/do/setting/system')">
						     <i class="el-icon-setting"></i>
						     <span slot="title">系统配置</span>
					      </el-menu-item>
						  
						  <el-menu-item index="31240" @click="$Jump('/do/express_company/list')">
						     <i class="el-icon-truck"></i>
						     <span slot="title">快递公司管理</span>
						  </el-menu-item>
						  
						  <el-menu-item index="450" @click="$Jump('/do/api_account/list')">
						     <i class="el-icon-connection"></i>
						     <span slot="title">第三方接口账户</span>
						  </el-menu-item>

						  <el-menu-item index="4510" @click="$Jump('/do/message/sms')">
						     <i class="el-icon-message"></i>
						     <span slot="title">短信消息记录</span>
						  </el-menu-item>
						  
						  <el-menu-item index="4511" @click="$Jump('/do/message/app')">
						     <i class="el-icon-message"></i>
						     <span slot="title">站内消息</span>
						  </el-menu-item>
						  
						 
					    </el-menu>
				</vuescroll>
			</div>
		</div>
		
	</div>
	
	<div class="Desk">
		
		<div class="Top">
			<div class="LeftMenuCollapse">
				<div class="Slider" v-if="$store.getters.getIsLeftMenuCollapse == false" @click="SwitchMenuCollapse(true)">
					<span  class="el-icon-s-fold"></span>
				</div>
				
				<div class="Slider" v-if="$store.getters.getIsLeftMenuCollapse == true" @click="SwitchMenuCollapse(false)">
					<span  class="el-icon-s-unfold" ></span>
					
				</div>
			</div>
			<div class="Position">后台  /  管理中心</div>
			<div class="Avatar">
				<div class="Img">
					<img src="../../assets/img/common/avatar_default.png" />
				</div>
				<div class="Info">
					<li>
						<el-dropdown @command="handleCommand">
						  <span class="el-dropdown-link">
						    运营管理<i class="el-icon-arrow-down el-icon--right"></i>
						  </span>
						  <el-dropdown-menu slot="dropdown">
						    <el-dropdown-item command="logout" divided>退出</el-dropdown-item>
						  </el-dropdown-menu>
						</el-dropdown>
					</li>
					<li>身份:{{$store.getters.getIdentityName}}</li>
				</div>
			</div>
		</div>
		
		<div class="Center">
			<div style="width: 100%;height: 100%;">
				<vuescroll :ops="vuescroll_ops2" ref="vs2">
					<div style="width: 100%;">
						<div>
							<router-view/>
						</div>
						<div class="Footer">© 2018-2020 {{$store.getters.getAppName}} 版权所有 闽ICP备18019047号-1</div>
					</div>
				</vuescroll>
			</div>
		</div>
		
	</div>
	
</div>
</template>

<script>
	import vuescroll from 'vuescroll'
	import {Menu,Submenu,MenuItem,MenuItemGroup,Dropdown,DropdownMenu,DropdownItem} from 'element-ui'
	export default {
	  name: 'MyDispatch',
	  props: {
	  },
	  data() {
	      return {
			  vuescroll_ops: {
			  	vuescroll: {},
			  	scrollPanel: {},
			  	rail: {
			  	},
			  	bar: {
			  		onlyShowBarOnScroll:false,
			  		background: '#ffffff',
			  		opacity: 0.1,
			  	}	
			  },
			  vuescroll_ops2: {
			  	vuescroll: {},
			  	scrollPanel: {},
			  	rail: {
			  	},
			  	bar: {
			  		onlyShowBarOnScroll:false,
			  		background: '#000000',
			  		opacity: 0.2,
			  	}	
			  }
	      }
	  },
	  computed: {
	  	newToken() {
	  		return this.$store.getters.getToken
	  	},
	  },
	  watch: {
	  	newToken: {
	  		handler(_new, _old) {
	  			this.TokenChange(_new,_old)
	  		}
	  	}
	  },
	  created() {
	  	if(this.$store.getters.getToken == ''){
			this.$Jump('/login')
		}
	  },
	  components: {
		vuescroll,
		'el-menu':Menu,
		'el-submenu':Submenu,
		'el-menu-item':MenuItem,
		'el-menu-item-group':MenuItemGroup,
		'el-dropdown':Dropdown,
		'el-dropdown-menu':DropdownMenu,
		'el-dropdown-item':DropdownItem
	  },
	  methods:{
		  TokenChange(_val,_old){
	
			  if(_val == ''){
				  this.$Jump('/login')
			  }
		
		  },
		  SwitchMenuCollapse(_v){
			  this.$store.dispatch('setIsLeftMenuCollapse',_v)
		  },
		  Logout(){
			  localStorage.setItem("LoginData",'')
			  this.$store.dispatch('setToken','')
			  this.$store.dispatch('setUserName','')
			  this.$store.dispatch('setIdentity','')
			  this.$store.dispatch('setIdentityName','')
			 
			  
			  this.$Jump('/login')
		  },
		  handleCommand(command) {
			  if(command == 'logout'){
				  this.Logout()
			  }
		  }
	  }
	}
</script>

<style scoped>
.My{
	width: 100%;
	height: 100%;
	display: flex;
	flex-wrap: nowrap;
}
.My .Nav{
	width: 220px;
	height: 100%;
	background-color: rgba(38,59,97,1);
	display: flex;
	flex-direction:column;
}
.My .Nav .Title{
	display: flex;
	align-items: center;
	height: 60px;
	width: 100%;
	padding-bottom: 10px;
	margin-bottom: 10px;
}
.My .Nav .Title img{
	width: 35px;
	height: 35px;
	margin-left: 10px;
	opacity: 0.8;
}
.My .Nav .Title ul{
	flex: 1;
	margin-left: 10px;
}
.My .Nav .Title ul h1{
	font-size: 1.2rem;
	height: 20px;
	line-height: 20px;
	color: rgba(255,255,255,0.6);
}
.My .Nav .Title ul li{
	height: 14px;
	line-height: 14px;
	cursor: pointer;
}
.My .Nav .Menus{
	flex: 1;
	width: 100%;
	overflow: hidden;
}
.My .Nav .Menus .Tips{
	line-height: 30px;
	font-size: 0.9rem;
	color: rgba(255,255,255,0.3);
	padding-left: 30px;
}

.My .Desk{
	flex: 1;
	display: flex;
	flex-direction: column;
}
.My .Desk .Top{
	padding: 10px 10px;
	display: flex;
	background-color: #FFFFFF;
	align-items: center;
	border-bottom: 1px solid rgba(0,0,0,0.04);
}
.My .Desk .Top .LeftMenuCollapse{
}
.My .Desk .Top .Position{
	flex: 1;
	margin-left: 10px;
	margin-right: 10px;
}
.My .Desk .Top .Avatar{
	display: flex;
	align-items: center;
}
.My .Desk .Top .Avatar .Img{
	margin-right: 10px;
}
.My .Desk .Top .Avatar .Img img{
	width: 40px;
	height: 40px;
	border-radius: 20px;
}
.My .Desk .Top .Avatar .Info{
	flex: 1;
}
.My .Desk .Top .Avatar .Info li{
	font-size: 0.9rem;
	cursor: pointer;
	color: #999999;
}
.My .Desk .Center{
	flex: 1;
	width: 100%;
	overflow: hidden;
}
.Footer{
	padding: 20px;
	text-align: center;
	color: #999999;
	font-size: 0.9rem;
}

/deep/ .Menus .el-menu-item:hover {
   background-color: rgba(0, 0, 0, 0.1) !important;
}
/deep/ .Menus .el-menu-item.is-active {
   background-color: rgba(0, 0, 0, 0.1) !important;
}


/deep/ .Menus .el-submenu:hover {
   background-color: rgba(0, 0, 0, 0.1) !important;
}
/deep/ .Menus .el-submenu.is-active {
   background-color: rgba(0, 0, 0, 0.1) !important;
}
</style>
